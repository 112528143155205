import styled, { createGlobalStyle } from 'styled-components';
import { Text } from '@yandex-lego/components/Text/bundle';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

export const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    background: rgba(239, 242, 247, 1);
    max-width: 100%;
    height: 100%;
    overflow-x: hidden;
    margin: 0;
  }
  
  html {
    height: 100%;
  }
  
  #root {
    height: 100%;
    max-width: 100%;
  }
  
`;

export const CodeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Description = styled(Text)<{ $isMobile: boolean }>`
    color: #000c24b2;
    line-height: ${({ $isMobile }) => ($isMobile ? '28px' : '32px')};
    margin-top: ${({ $isMobile }) => ($isMobile ? '22px' : '30px')};
    text-align: center;
`;

export const ContentWrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: ${({ isMobile }) => (isMobile ? '260px' : '301px')};

    margin-bottom: ${({ isMobile }) => (isMobile ? `-52px` : `-43px`)}; ;
`;

export const QrYandexWrapper = styled.div`
    margin-left: 20px;
    margin-right: 20px;
`;

export const SingleButton = styled(Button)<{ $isMobile: boolean }>`
    margin-top: ${({ $isMobile }) => ($isMobile ? '32px' : '28px')};

    height: 44px;
    border-radius: 14px;
    padding: 10px 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        background-color: #b0bdd633;
        border-radius: 14px;
    }

    &:not([aria-disabled='true']):hover:before {
        background-color: #a5b1ca4d;
    }

    & .Button2-Text {
        font-size: 16px;
        line-height: 24px;
        color: #2f3747;

        font-family: var(--text-body-long-size-xl-font-family);

        margin: 0;
    }
`;

export const Link = styled.a`
    text-decoration: none;
`;
