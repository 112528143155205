import React, { FC } from 'react';
import {
    CodeWrapper,
    ContentWrapper,
    Description,
    Link,
    MainWrapper,
    QrYandexWrapper,
    SingleButton,
    TextWrapper,
} from './styles';
import { QrYandex } from '../../images/qrYandex/QrYandex';
import { useIsMobile } from '../../hooks/useIsMobile/useIsMobile';
import { mobileWidth } from '../../constants/QR';
import { Layout } from '../../components/Layout/Layout';

const CodeError: FC<{ isMobile: boolean }> = ({ isMobile }) => {
    return (
        <svg
            width={isMobile ? '60' : '64'}
            height={isMobile ? '87' : '91'}
            viewBox="0 0 64 91"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M46 8V83" stroke="#2F3747" strokeWidth="16" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M46 8L8 68.5H55.5"
                stroke="#2F3747"
                strokeWidth="16"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const NotFoundPage = () => {
    const [isMobile] = useIsMobile(mobileWidth);

    return (
        <Layout isMobile={isMobile}>
            <MainWrapper>
                <ContentWrapper isMobile={isMobile}>
                    <CodeWrapper>
                        <CodeError isMobile={isMobile} />
                        <QrYandexWrapper>
                            <QrYandex isMobile={isMobile} />
                        </QrYandexWrapper>
                        <CodeError isMobile={isMobile} />
                    </CodeWrapper>
                    <TextWrapper>
                        <Description $isMobile={isMobile} typography={isMobile ? 'headline-s' : 'headline-m'}>
                            Запрашиваемая страница не&nbsp;найдена
                        </Description>
                        <Link href={document.location.origin}>
                            <SingleButton $isMobile={isMobile} view="default" size="m">
                                Вернуться в QR-генератор
                            </SingleButton>
                        </Link>
                    </TextWrapper>
                </ContentWrapper>
            </MainWrapper>
        </Layout>
    );
};
