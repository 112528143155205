import React from 'react';
import ReactDOM from 'react-dom';
import { NotFoundPage } from './NotFoundPage';

ReactDOM.render(
    <React.StrictMode>
        <NotFoundPage />
    </React.StrictMode>,
    document.getElementById('root'),
);
